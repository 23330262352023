/**
 *
 */

.cta {
  background: #4a4a4a;
  color: var(--xs-cta-color);
  font-size: 18px;
  font-weight: 500;
  padding: 0 15px 8px;
  
  a {
    color: var(--xs-cta-color);
  }



  @media (min-width: 768px) {
    background: rgba(0, 0, 0, 0.6);
    border-left: 0px solid transparent;
    border-bottom: 10px solid var(--primary);
    padding: 15px 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    /*background: var(--secondary);*/
    display: table-cell;
    float: none;
    font-size: 16px;
  }

  @media (min-width: 992px) {
    padding: 12px 20px 5px;
    margin-top: -210px;
    right: 0;
  }

  @media (min-width: 1200px) {
    border-left-width: 0px;
  }

  strong {
    font-size: 21px;
    line-height: 24px;
    font-weight: 700;
    display: block;
    margin: 14px 0 0;

    @media (min-width: 768px)  and (max-width: 991px) {
      font-size: 24px;
      line-height: 32px;
/*      margin: 0 0 15px 0;
      border-bottom: 2px solid #9b9b9b;
      padding-bottom: 4px;*/
    }

    @media (min-width: 992px) {
      font-size: 30px;
      line-height: 1;
      margin: 0px 0 6px;
    }
  }

  p {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 0;

    @media (min-width: 768px) {
      padding-top: 2px;
      font-size: 18px;
    }

    @media (min-width: 992px) {
    }
    
    span {
      color: var(--contrast);
    }
  }
  
  .form-cta {
    border-top: 1px solid #9b9b9b;
    border-bottom: 1px solid #9b9b9b;
    font-size: 16px;
    line-height: 1;
    padding: 6px 0;
    margin-bottom: 10px;

    @media (min-width: 768px)  and (max-width: 991px) {
     }
  }
  
  form.row{
    padding: 0 30px;
    
    @media (min-width: 768px)  and (max-width: 991px) {
      padding: 0 10px;
    }

    .form-group{
      margin-bottom: 8px;

      @media (min-width: 768px) {
        display: table;
        width: 100%;
      }
      
      &::after {
        content: "";
        display: block;
        clear: both;
      }

      label {
        padding: 0;
        text-align: right;

        @media (min-width: 768px) {
          display: table-cell;
          float: none;
          vertical-align: middle;
        }
      }

      .col-sm-8 {
        
        text-align: left;
        @media (min-width: 768px) {
          display: table-cell;
          float: none;          
        }
      }

      .form-control {
        height: 50px;
      }
    }    
  }

  .form-tnc {
    font-size: 11px;
    line-height: 13px;
  }

  .btn {
    background: var(--xs-button-background);
    border: 0px solid;
    border-radius: 8px;
    color: var(--xs-button-color);
    padding: 15px 42px;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;

    @media (min-width: 768px) {
      margin: 0;
      padding: 15px 24px;
    }

    @media (min-width: 992px) {
      margin: 0;
      padding: 15px 42px;
    }

    @media (min-width: 1200px) {
      margin: 0;
      padding: 15px 37px;
    }
  }
}

.hero {
  .cta {
    p{
      @media (min-width: 768px)  and (max-width: 991px) {
       }      
    }
  }
}
