/**
 *
 */

.top {

  background: var(--xs-header-background);
  border: 0;
  margin-bottom: 0;

  @media (min-width: 768px) {
    border-radius: 0;
    background: var(--sm-header-background);
  }

  @media (min-width: 992px) {
    background: var(--md-header-background);
  }

  .container {
    padding-bottom: 12px;

    @media (max-width: 767px) {
      padding-bottom: 0px;
    }

    @media (min-width: 768px) {
      background: var(--sm-header-container-background);
    }

    @media (min-width: 992px) {
      height: 136px;
      background: var(--md-header-container-background);
      padding-bottom: 4px;
    }
  }

  a.phone {
    font-weight: 600;
    text-decoration: none;
    font-size: 21px;
    line-height: 30px;
  }

  

  .navbar-right.phone {
    margin-top: 22px;
    color: #d2232a;
    @media (max-width: 991px) {
      display: none;
    }

  }

  .container.phone {
    display: none !important;
  }

  .navbar-toggle {
    z-index: 2;
    float: left;
    border-radius: 0;
    margin: 0;
    padding: 10px 12px 11px;
    border: 0;

    .icon-bar {
      height: 3px;
      width: 25px;
    }
  }

  .logos {
    min-width: 320px;

    @media (min-width: 992px) {
      height: 136px;
      padding: 12px 0px 0px 8px;
    }

    @media (max-width: 991px) {
      width:100%;
      text-align:center;

    }
  }

  .navbar-header {

    .brands {
      padding-right: 0px;
    }

    .navbar-brand {
      background: url('/assets/dist/images/logo.png') center top no-repeat;
      background-size: contain;
      height: 92px;
      width: 230px;
      text-indent: -999em;

      @media (max-width: 767px) {
        max-height: 50px;
        margin: 0px 15px;
        width: 125px;
        height: 50px;
      }

      @media (max-width: 991px) {
        margin: 0px 20px 0px 0px;
        float: none;
        display: inline-block;
      }

      @media (min-width: 768px) {
        margin: 0px 14px 0px 10px;
      }

    }

    
    .navbar-brand.brand-2 {
        background: url('/assets/dist/images/camping-world-logo.png') center top no-repeat;
        background-size: contain;
        height: 61px;
        width: 63px;
        text-indent: -999em;

        @media (max-width: 767px) {
          margin: 0px 15px;
        }

        @media (min-width: 768px)  {
          margin: 0px 20px 16px 15px;
        }

      }

      &.brands .divider {
          border-left: 1px solid #ccc;
          display: inline-block;
          float: none;
          margin: 0px 0px 0px 0px;
          width: 1px;
          height: 100px;
          @media (max-width: 767px) {
            height: 50px;
          }
      }
  }

  
}


/**
 *
 */




.navbar-collapse {
 

  ul.navbar-nav {

    margin: 0 auto;
    padding-right: 0px;
    .caret {
      font-size: 14px;
      line-height: 46px;
      display: inline-block;
      background: transparent;
      &::before {
        left: 15px;
        top: -9px;
        font-size: 14px;
      }
    }

    .open {
      background: var(--xs-menu-item-background);

      .dropdown-menu {
        text-align: center;
        padding: 0;

        .divider {
          background: var(--xs-menu-background);
          margin: 0;
          height: 9px;
        }
      }
    }

    .open > a,
    .open > a:focus,
    .open .dropdown-menu > li > a,
    & > li > a,
    & > li > a:focus {
      font-weight: normal;
      border: 0px;
      background: var(--xs-menu-item-background);
      border-top: 1px solid var(--xs-hamburger-color);
      color: var(--xs-menu-item-color);
      font-size: 16px; 
      line-height: 59px;
      padding: 0;
      text-decoration: none;

      @media (min-width: 768px) {
        padding: 0 25px;
      }

      @media (min-width: 992px) {
        padding: 0 35px;
        line-height: 48px;
        border: 0;
      }

      @media (min-width: 1200px) {
        padding: 0 44px;
        line-height: 50px;
        
      }

      &:hover {
        background: var(--xs-menu-background);
        color: var(--xs-menu-item-color);
      }
    }

    .open > a,
    .open > a:focus,
    .open .dropdown-menu > li > a,
    & > li > a,
    & > li:not(.menu-brand) > a:focus {
      padding: 0px 25px;

      @media (min-width: 992px) {
        padding: 0px 45px 0px 35px;
      }  
      @media (min-width: 1200px) {
        padding: 0px 48px 0px 46px;
      }
      &:hover {
        background-color: #555;
        color: #FFFFFF;
      }
    }

    .dropdown-menu {
      @media (min-width: 768px) and (max-width: 992px) {
        width: 100%;
        position: relative;
        float: none;
        box-shadow: unset;
      }
    }

    li.close {
      display: block;
      float: none;
      line-height: initial;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 0;

      @media (min-width: 768px) and (max-width: 991px) {
        z-index: 4;
      }

      > a:hover,
      > a {
        background: var(--xs-hamburger-background);
        border: 0;
        color: var(--xs-hamburger-color);
        width: 50px;
        line-height: 41px;
        text-shadow: none;
        font-size: 22px;

        &:hover {
          background: var(--xs-hamburger-background);
          color: var(--xs-hamburger-color);
        }

        @media (min-width: 768px) {
          padding: 0;
        }
      }
    }
  }
}

.hero .wrapper .container .cta {
  @media (min-width: 768px) {
    margin-bottom: 10px;
  }
}

.hero .col-md-7  {
  font-weight: normal;
  strong {
    font-weight: normal;
  }

  h1 {
    font-weight: normal;
  }
}

.locator .container {
  background-image: url(/assets/dist/images/map-blurred.jpg);
}

#start-geolocate .location-holder .btn {
    display: block;
    float: left;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    background-color: #FFD50C;
    padding: 14px;
    border: 1px solid #fff;
}

#start-geocoder {
    display: block;
    float: left;
    margin-left: 25px;
    padding-left: 25px;
    border-left: 1px solid #bbb;
}

#start-geocoder .geocoder-pin-far-right .btn-search {
    height: 50px;
    background-color: #FFD50C;
    padding: 14px 17px;
    border: none;
    border-radius: 0 8px 8px 0;
    font-size: 0px;
    line-height: 1;
    font-weight: 700;
}

.cta .btn:hover, .cta .btn:focus,
#location-search .btn-search:hover, 
#location-search .btn-search:hover::before, 
#start-geocoder .geocoder-pin-far-right .btn-search:hover,
#start-geolocate .location-holder .btn:hover,
#location-search .btn-search:focus, 
#start-geocoder .geocoder-pin-far-right .btn-search:focus,
#start-geolocate .location-holder .btn:focus {
  background: #303030 !important;
  color: #FFD50C !important;
}


.hasPOS .list-group-item a, .availableFrom .list-group-item a, .areaServed .list-group-item a, .makesOffer .list-group-item a, .containsPlace .list-group-item a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  };
}


#start-geocoder input {
    display: block;
    float: left;
    background-color: #fff;
    border: 1px solid #ccc;
    height: 50px;
    width: 240px;
    padding: 18px 15px;
    font-size: 14px;
    line-height: 17px;

}

.locator-drawer .container h2 > a.dropdown-toggle {
  background: #555;
  color: #FFFFFF;
  .caret {
    color: #FFFFFF;
  }
}

#list-panel #location-list {
  padding-right: 30px;
}
#list-panel .place .interact {
  text-align: center;
  width: 100%;
  padding: 30px 17px 15px 15px; 

  @media (min-width: 992px) {
    padding: 73px 17px 0px 0px; 
  }
}
#list-panel .place .btn {
  width: 100%;
  color: #FFFFFF;
  height: 50px;
  line-height: 40px;
}

#list-panel .pager a.right, #list-panel .pager a.left {
  height: 50px;
  padding-top: 17px;
}

 
.navbar-collapse ul.navbar-nav li.close > a {
  width: 44px;
  height: 36px;
  padding: 0px 0px;
}


.navbar-collapse ul.navbar-nav li.close > a:hover,
.navbar-collapse ul.navbar-nav li.close > a:focus {
  background-color: #ddd !important;
  width: 44px;
  height: 36px;
  padding: 0px 0px;
}

.navbar-collapse ul.navbar-nav li > .navbar-toggle:first-child{
  &:hover, &:focus {
    background-color: #ddd !important;
  };
}

.content .container .btn-danger {
    background: #FFD50C;
    border: 0px solid;
    border-radius: 8px;
    color: #555;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
}

body > .location .location-detail {
  @media (min-width: 768px) {
    padding-top: 80px;
  }
}
body > .location .container > .row{
  @media (min-width: 768px) {
    background: var(--sm-location-background);
    margin: 0 0 0 15px;
    padding: 0;
    min-height: 400px;
  }
}

body > .location .location-detail .additional-info {
  padding: 0px 30px;
}

body > .location .location-detail .additional-info a {
  color: #4f9cba;
}

body > .location .location-detail .additional-info p.info-1 {
  text-align: center;
  padding-bottom: 20px;
}

#list-panel .place .links {
  padding-left: 30px;
}



