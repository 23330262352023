#location-list {
  p.no-locations {
    background: var(--xs-map-item-background);
    color: var(--xs-map-item-color);
    font-size: 18px;
    padding: 30px;
    text-align: center;

    @media (min-width: 768px) {
      margin: 0;
    }
  }
}

#list-panel {
  padding: 12px 10px 8px;
  position: relative;

  @media (max-width: 768px) {
    background: #ccc;
    padding: 12px 10px 5px;
  }

  @media (min-width: 768px) {
    padding: 0 15px;
    display: block;
  }

  @media (min-width: 992px) {
    padding: 0;
    width: 470px;
  }

  @media (min-width: 1200px) {
    width: 500px;
  }

  #location-list {
    position: relative;
    min-height: 640px;

    @media (min-width: 320px) and (max-width: 767px) {
      min-height: 310px;
    }

    @media (min-width: 768px) {
      background: var(--sm-map-item-background);
    }

    @media (min-width: 768px) and (max-width: 991px) {
      min-height: 445px;
    }
  }

  #list-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.75);
    min-height: 100%;
    z-index: 9;

    @media (min-width: 320px) and (max-width: 767px) {
      background: var(--secondary);
      min-height: 310px;
    }

    .loader-img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50px;
      height: 50px;
      margin: -25px 0 0 -25px;
    }
  }

  .list-group {
    @media (min-width: 768px) {
      overflow: auto;
      max-height: 443px;
      margin: 0;
    }

    @media (min-width: 992px) {
      height: 640px;
      max-height: none;
    }
  }

  .list-group-item {
    border: none;

    &:first-child {
      border-radius: 0;
    }

    &.active:hover {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .place {
    background: var(--xs-map-item-background);
    border-radius: 0;
    color: var(--xs-map-item-color);
    font-size: 14px;
    margin: 0;
    padding: 22px 10px 14px;
    text-align: center;

    @media (min-width: 768px) {
      background: var(--sm-map-item-background);
      border-bottom: 1px solid #cfcfcf;
      cursor: pointer;
      text-align: left;
      padding: 0 15px 40px;
    }

    @media (min-width: 992px) {
      padding: 0 15px 18px;
    }

    &.active {
      background: var(--sm-map-active-background);

      &:hover {
        color: var(--xs-map-item-color);
      }
    }

    strong {
      color: var(--xs-map-item-strong-color);
      font-weight: 600;
    }

    strong.name {
      display: block;
      font-size: 20px;
      margin-bottom: 10px;

      @media (max-width: 767px) {
        margin-bottom: 0px;
      }

      a {
        font-size: 18px;
        line-height: 21px;
      }
    }

    .address {
      @media (min-width: 768px) {
        padding: 25px 15px 0 25px;
      }

      @media (min-width: 992px) {
        padding: 18px 0 10px 30px;
      }

      > .hidden-xs {
        font-size: 13px;
        line-height: 20px;
        
        .telephone {
          a {
            color: #666;
            text-decoration: none;
          }
        }
      }
    }

    .street {
      a {
        text-decoration: none;
      }
    }

    .hours {
      line-height: 17px;
      margin-top: 10px;

      @media (min-width: 768px) {
        font-size: 13px;
        padding: 30px 0 0 50px;
        line-height: 22px;
      }

      @media (min-width: 992px) {
        padding: 3px 30px 0;
      }

      strong {
        @media (min-width: 768px) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          display: block;
          font-size: 14px;
          margin: 0 0 4px;
        }
      }

      dl {
        margin: 0 0 6px;
      }

      dd,
      dt {
        display: inline;
      }

      dt {
        font-weight: normal;

        &::after {
          content: ": ";
        }
      }
    }

    .interact {
      @media (min-width: 768px) {
        padding: 40px 23px 0 50px;
        text-align: center;
      }

      @media (min-width: 992px) {
        padding: 73px 17px 0 5px;
        float: right;
      }

      @media (min-width: 1200px) {
        padding: 73px 18px 0;
      }
    }

    .btn {
      padding: 5px 34px;
      background: var(--secondary);
      color: var(--text-lightest);
      border-radius: 4px;

      @media (max-width: 767px) {
        margin-top: 10px;
      }

      @media (min-width: 768px) {
        display: block;
        padding: 4px 0;
        margin: 0;
      }
    }
    
    .conditions-msg {
      text-align: center;
      font-size: 13px;
      line-height: 16px;
      margin-top: 12px;
      
     @media (min-width: 768px)  and (max-width: 991px) {
      text-align: left;
      padding-top: 28px;
     }
      
      p {
        margin: 0;
      }
    }
    
  }

  .icons {
    padding-top: 18px;

    a {
      font-size: 12px;
      text-decoration: none;
    }

    i {
      display: block;
      font-size: 25px;
    }
  }

  .pager {
    background: var(--xs-map-more-background);
    border-radius: 10px;
    font-size: 15px;
    margin: 7px 0;

    .col-xs-3,
    .col-xs-6 {
      padding: 0;
    }

    a {
      background: var(--secondary);
      border: 0;
      border-radius: 0;
      color: var(--text-lightest);
      font-size: 16px;
      min-width: 75px;
      padding: 9px 14px 8px;

      &.left {
        border-radius: 4px 0 0 4px;
        float: left;
      }

      &.right {
        border-radius: 0 4px 4px 0;
        float: right;
      }
    }

    li.col-xs-6 {
      color: var(--xs-map-more-color);
      line-height: 38px;
    }
  }
}

.location.map {
  #list-panel {
    .place {
      cursor: default;
    }
  }
}
