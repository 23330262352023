/**
 *
 */

.hero {
  /*background-color: var(--xs-hero-background);*/
  background: url('/assets/dist/images/hero.jpg') center top no-repeat;
  background-size: cover;
  color: var(--xs-hero-color);
  z-index: -1;
  padding-left: 0;
  padding-right: 0;
  padding-top: 210px;
  @media (min-width: 320px)  and (max-width: 991px){
    background: none;
    padding-top: 0px;
  }



  @media (max-width: 767px) {
    text-align: center;
  }

  @media (min-width: 992px) {
    background-color: var(--md-hero-background);
    color: var(--md-hero-color);
  }


    
    .wrapper{
      width: 100%;
/*      background: linear-gradient(90deg, #000b 0%, #fff0 75%);*/
      background: #000000; /* Old Browsers */
      background: -webkit-linear-gradient(left, rgba(0,0,0,0.72) 0%, rgba(255,255,255,0) 75%); /*Safari 5.1-6*/
      background: -o-linear-gradient(left,#000b,#fff0); /*Opera 11.1-12*/
      background: -moz-linear-gradient(left,#000b 0%,#fff0 75%); /*Fx 3.6-15*/
      background: linear-gradient(to right, #000b 0%, #fff0 75%); /*Standard*/      
      
      position: relative;
      
      @media (min-width: 320px)  and (max-width: 991px){
        margin-top: 0;
        background: url('/assets/dist/images/hero.jpg') right top no-repeat;
        background-size: cover;
      }
      
      .container{
        position: relative;
        
        @media (min-width: 768px)  and (max-width: 991px){
          .row {
           margin: 0; 
          }
        }
        
      }
    }
  

  .header-sm {
    background-color: var(--xs-hero-background);
    color: #fff;
    text-align: center;

    strong {
      font-size: 30px;
      line-height: 30px;
      margin: 13px 10px 12px;
      display: block;
      font-weight: 600;
    }
  }

  .col-md-7 {
    line-height: 22px;
    color: #fff;

    @media (max-width: 767px) {
      background-color: var(--xs-hero-background);
    }

    @media (min-width: 768px) and (max-width: 991px) {
      display: table-cell;
      float: none;
/*      background: url('/assets/dist/images/hero-sm.jpg') center top no-repeat;
      background-size: cover;*/
      text-align: center;
      width: 45%;
    }

    a {
      color: var(--xs-hero-color);
      @media (min-width: 992px) {
        color: var(--md-hero-color);
      }
    }

    strong {
      font-size: 30px;
      line-height: 30px;
      margin: 13px 10px 12px;
      display: block;
      font-weight: 600;
      widows: 2;

      @media (min-width: 768px)  and (max-width: 991px){
        display: none;
      }

      @media (min-width: 992px) {
        font-size: 27px;
        font-weight: 600;
        margin: 16px 0;
        line-height: 30px;
      }

      @media (min-width: 1200px) {
        font-weight: bold;
        margin: 18px 0 10px;
        font-size: 30px;
        line-height: 33px;
      }
    }

    @media (min-width: 992px) {
    }

    h1 {
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
      margin: 0 0 10px;
  
      @media (min-width: 992px) {
        margin-top: 16px;
        font-size: 16px;
        line-height: 18px;
      }
  
      @media (min-width: 1200px) {
        font-size: 18px;
      }
    }
  }


  p {
    font-size: 16px;
    line-height: 20px;
    min-height: 1em;
    margin-bottom: 15px;
    
    @media (min-width: 992px) {
      font-size: 14px;
      line-height: 18px;
    }
  
      @media (min-width: 1200px) {
        font-size: 16px;
        line-height: 20px;
      }
  }
}
