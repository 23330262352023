:root {
  --btn-width: 56px;
}

#location-search {
  padding: 16px 15px;
  background: var(--xs-search-background);

  @media (min-width: 768px) {
    padding: 22px 15px 20px;
    background: var(--sm-search-background);
    min-height: 92px;
  }

  @media (min-width: 992px) {
    padding: 22px 30px 20px;
  }

  @media (min-width: 1200px) {
    padding: 22px 112px 20px;
  }

  .mapboxgl-ctrl-geocoder {
    border-radius: 0;
    background: none;
    box-shadow: none;
    text-align: left;
    position: relative;
    width: 100%;
    max-width: none;
    min-width: 0;

    &::before {
      display: none;
      line-height: 50px;
      font-size: 16px;
      color: var(--sm-search-color);
      position: absolute;
      text-align: center;
      font-weight: 500;
      width: 144px;
      left: -144px;
    }

    &::after {
      clear: both;
      content: " ";
      display: block;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      max-width: 450px;
      margin: 0 auto;
    }

    input {
      height: 50px;
      font-size: 14px;
      font-family: 'Verdana';
      padding: 0 15px;
      width: calc(100% - var(--btn-width));
      display: block;
      float: left;
      background: #fff;
    }

    .geocoder-pin-right {
      display: none;
    }

    .geocoder-pin-far-right {
      width: var(--btn-width);
      display: block;
      float: left;
    }

    & > div {
      display: block;
      float: left;
    }
  }

  .btn-search {
    background: var(--xs-search-button-background);
    color: transparent;
    border: 0;
    width: var(--btn-width);
    font-size: 0px;
    font-family: OpenSans, 'Open Sans', Arial, sans-serif;
    line-height: 1;
    font-weight: bold;
    padding: 13px 17px;
    border-radius: 0 10px 10px 0;

    &::before {
      content: "\f002";
      color: var(--text-darkest);
      font: normal normal normal 24px/1 FontAwesome !important;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin: 0;
    }    
    
    @media (min-width: 768px) {
      border: 0;
    }
  }
}

.location {
  #location-search {
    @media (min-width: 768px) {
      padding: 21px;
    }

    .mapboxgl-ctrl-geocoder {
      @media (min-width: 768px) {
        max-width: none;
        left: 0;

        &::before {
          content: none;
        }
      }
    }
  }
}
